<template>
  <div class="h-full overflow-y-auto van-hairline--top">
    <div class="px-24" v-if="list.length > 0">
      <ul class="text-main text-16">
        <li
          class="vehicle-item flex items-center justify-between h-56 van-hairline--bottom"
          @click="onSelectVehicle(item, index)"
          v-for="(item, index) in list"
          :key="item.truckId"
        >
          <span>{{ item.carLicensePlate }}</span>
          <Icon name="icon_success" class="text-32" v-if="indexRef === index" />
        </li>
      </ul>
    </div>
    <div v-else class="empty m-auto mt-60 flex flex-col justify-center items-center">
      <img src="~@/assets/no-vehicle.png" />
      <span class="text-muted text-16">暂无车辆</span>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { defineComponent } from '@vue/composition-api';
import { useTruckStore } from '@/store/truck';
import { useSourceStore } from '@/store/source';
export default defineComponent({
  setup() {
    const indexRef = ref(0);
    const truckStore = useTruckStore();
    const sourceStore = useSourceStore();
    const list = computed(() => truckStore.trucks);

    const onSelectVehicle = (item, index) => {
      sourceStore.updateSelectTruck(item, index);
      indexRef.value = index;
    };

    return {
      list,
      indexRef,
      onSelectVehicle
    };
  }
});
</script>

<style lang="less" scoped>
.empty {
  width: 1.43rem;
  height: 1.43rem;
  img {
    max-width: 100%;
  }
}
</style>
