<template>
  <div class="h-full bg-white account-wrapper">
    <tabs class="h-full" type="line" border color="#0076FF" line-width="20">
      <tab class="h-content" title="我的">
        <div class="h-full overflow-y-auto">
          <ul class="h-full px-24" v-if="perListRef.length > 0">
            <li
              class="flex items-start justify-between py-12 van-hairline--bottom"
              v-for="item in perListRef"
              :key="item.payeeId"
              @click="handleClickItem(item, 1)"
            >
              <div class="flex">
                <div class="flex items-center">
                  <img
                    class="w-24 h-24 mr-16 rounded-20"
                    v-if="item.bankCode"
                    :src="getBankIconByCode(item.bankCode)"
                    alt=""
                  />
                  <img v-else :src="getBankIconByCode('DEFAULT')" class="mr-10 w-30 h-30" alt="" />
                </div>

                <div class="flex flex-col justify-center">
                  <span class="mt-2 text-main text-16">{{ item.bankName }} ({{ item.name }})</span>
                  <span class="mt-4 text-14 text-placeholder">{{ item.bankAccount }}</span>
                </div>
              </div>
              <Icon name="icon_success" class="text-32" v-if="item.payeeId === carrier1.payeeId" />
            </li>
          </ul>
          <div class="flex flex-col items-center justify-center m-auto empty mt-60" v-else>
            <img src="~@/assets/no-bank.png" />
            <span class="text-muted text-16">未绑定银行卡</span>
          </div>
        </div>
      </tab>
      <tab class="h-full" title="其他">
        <div class="h-full overflow-y-auto">
          <ul class="h-full px-24" v-if="carListRef.length > 0">
            <li
              class="flex items-start justify-between py-12 van-hairline--bottom"
              v-for="item in carListRef"
              :key="item.payeeId"
              @click="handleClickItem(item, 2)"
            >
              <div class="flex">
                <img class="w-24 h-24 mr-16 rounded-20" :src="getBankIconByCode(item.bankCode)" alt="" />
                <div class="flex flex-col justify-center">
                  <span class="mt-2 text-main text-16">{{ item.bankName }} ({{ item.name }})</span>
                  <span class="mt-4 text-14 text-placeholder">{{ item.bankAccount }}</span>
                </div>
              </div>
              <Icon name="icon_success" class="text-32" v-if="item.payeeId === carrier2.payeeId" />
            </li>
          </ul>
          <div v-else class="flex flex-col items-center justify-center m-auto empty mt-60">
            <img src="~@/assets/no-bank.png" />
            <span class="text-muted text-16">未绑定银行卡</span>
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';
import { defineComponent, ref, watch, computed } from '@vue/composition-api';
import useAccount from '../information/hooks/useAccount';
import { useSourceStore } from '@/store/source';
import { getBankIconByCode } from '../../../mine/constants';

export default defineComponent({
  components: { Tab, Tabs },
  setup() {
    const sourceStore = useSourceStore();
    const selectCarrierRef = computed(() => sourceStore.selectCarrier);
    const { perListRef, carListRef, handleSelectCarrier } = useAccount();

    const carrier1 = ref({ payeeId: 0 });
    const carrier2 = ref({ payeeId: 0 });

    const handleClickItem = (item, type) => {
      carrier1.value = { payeeId: 0 };
      carrier2.value = { payeeId: 0 };
      if (type === 1) {
        carrier1.value = item;
      } else if (type === 2) {
        carrier2.value = item;
      }
      handleSelectCarrier(item);
    };
    watch(
      selectCarrierRef,
      (oldValue, newValue) => {
        if (oldValue.payeeId === 0) {
          carrier1.value = { payeeId: 0 };
          carrier2.value = { payeeId: 0 };
        }
      },
      {
        deep: true
      }
    );

    return {
      perListRef,
      carListRef,
      handleSelectCarrier,
      handleClickItem,

      carrier1,
      carrier2,
      getBankIconByCode
    };
  }
});
</script>

<style lang="less" scoped>
.account-wrapper {
  /deep/ .van-tabs--line .van-tabs__wrap::after {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  /deep/ .van-tabs--line .van-tabs__wrap {
    flex-shrink: 0;
  }

  /deep/ .van-tab__text--ellipsis {
    font-size: 0.16rem;
    font-weight: bold;
  }
  /deep/ .van-tabs__content {
    height: calc(100% - 0.48rem);
  }

  .empty {
    width: 1.43rem;
    height: 1.43rem;
    img {
      max-width: 100%;
    }
  }
}
</style>
