import { defineStore } from 'pinia';
import { apiGetCarList, apiUpdateDefaultTruck } from '@api/mine';
import { useUserStore } from '@/store/user';
import { apiGetTrailerList } from '@api/trailer';
export const useTruckStore = defineStore({
  id: 'driver-truck',
  state: () => ({
    trucks: [],
    trailers: []
  }),
  getters: {},
  actions: {
    async queryUsersTruck(query) {
      try {
        const userStore = useUserStore();
        if (userStore?.driverAuthVO?.id) {
          // 已经司机认证
          const res = await apiGetCarList({
            driverId: userStore.driverAuthVO.id,
            ...query
          });
          const { data } = res;
          data && (this.trucks = data);
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async queryUsersTrailer(query) {
      try {
        const userStore = useUserStore();
        if (userStore?.driverAuthVO?.id) {
          // 已经司机认证
          const res = await apiGetTrailerList({
            driverId: userStore.driverAuthVO.id,
            ...query
          });
          const { data } = res;
          data && (this.trailers = data);
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async updateDefaultTruck(truckId) {
      try {
        const userStore = useUserStore();
        const res = await apiUpdateDefaultTruck({
          driverId: userStore.driverAuthVO.driverId,
          truckId
        });
        const { data } = res;
        if (data) {
          this.queryUsersTruck();
        }
        return !!data;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  }
});
