import { computed } from '@vue/composition-api';
import { useSourceStore } from '@/store/source';

function useAccount() {
  const sourceStore = useSourceStore();
  const perListRef = computed(() => sourceStore.perList); // 个人银行卡列表
  const carListRef = computed(() => sourceStore.carList); // 车队长行卡列表

  // const selectCarrier = ref({
  //   payeeId: 0
  // });

  // onBeforeMount(async () => {
  //   await sourceStore.queryCarrierList();
  // });

  // const handleCheckCarrier = item => {
  //   selectCarrier.value = item;
  // };

  const handleSelectCarrier = item => {
    sourceStore.updateInfo(item);
    console.log(sourceStore);
  };

  return {
    perListRef,
    carListRef,
    handleSelectCarrier
  };
}

export default useAccount;
