import BigNumber from 'bignumber.js';
import { SmallChangeLabels } from '@/costsService/constants';
const unitData = { 1: '重量', 2: '体积', 3: '件数' };
const priceUnitData = { 1: '吨', 2: '方', 3: '件' };
const OtherPenaltyEnum = {
  YUAN: 1,
  PERCENT: 2
};
export const getPriceRule = (data, weightUnit) => {
  if (!data || typeof data.pricePlanType !== 'number') return '';

  const otherFineText = data.otherFine
    ? data.otherFineUnit === OtherPenaltyEnum.YUAN
      ? ` - ${new BigNumber(data.otherFine)
          .dividedBy(100)
          .decimalPlaces(2, BigNumber.ROUND_DOWN)
          .toNumber()}元`
      : ` * (1 - ${data.otherFine}%)`
    : '';
  const left = data.otherFine && data.otherFineUnit === OtherPenaltyEnum.PERCENT ? '(' : '';
  const right = data.otherFine && data.otherFineUnit === OtherPenaltyEnum.PERCENT ? ')' : '';

  if (data.pricePlanType === 1) {
    return data.totalTransportPrice !== null
      ? `运费 = ${new BigNumber(data.totalTransportPrice)
          .dividedBy(100)
          .decimalPlaces(2, BigNumber.ROUND_DOWN)
          .toNumber()}元${otherFineText}`
      : '';
  }
  let transportUnit = unitData[String(data.priceUnitType)];
  const unit = data.priceUnitType === 1 && weightUnit === 2 ? '千克' : priceUnitData[String(data.priceUnitType)];
  let priceUnit = `元/${unit}`;
  let lossUnit = data.allowLossNumberUnit ? '‰' : unit;
  let smallChangeRule =
    (!data.smallChangeRule && data.smallChangeRule === 0) || (data.smallChangeRule && data.smallChangeRule === 1)
      ? ' - ' + SmallChangeLabels[String(data.smallChangeRule)]
      : '';
  let conversion = data.allowLossNumberUnit
    ? 100
    : data.priceUnitType === 3
    ? 1
    : data.priceUnitType === 1 && weightUnit === 1
    ? 1000000
    : 1000;

  const priceConversion = data.priceUnitType === 1 && weightUnit === 2 ? 100000 : 100;
  const transportPrice = new BigNumber(data.transportPrice)
    .dividedBy(priceConversion)
    .decimalPlaces(2, BigNumber.ROUND_DOWN);

  if (!data.settlementMethod) {
    return `运费 = ${left}装货${transportUnit} * ${transportPrice}${priceUnit}${right}
        ${otherFineText}${smallChangeRule}`;
  } else if (data.settlementMethod === 1) {
    return `运费 = ${left}卸货${transportUnit}
          *
          ${transportPrice}${priceUnit}
          ${
            data.lossRule && data.lossPrice
              ? ` - (装货${transportUnit} - 卸货${transportUnit} - ${
                  data.allowLossNumberUnit === 1 ? `装货${transportUnit} *` : ``
                } ${new BigNumber(data.allowLossNumber)
                  .dividedBy(conversion)
                  .decimalPlaces(3, BigNumber.ROUND_DOWN)}${lossUnit}) * ${new BigNumber(data.lossPrice)
                  .dividedBy(priceConversion)
                  .decimalPlaces(2, BigNumber.ROUND_DOWN)}${priceUnit}`
              : ''
          }
          ${right}${otherFineText}${smallChangeRule}`;
  } else if (data.settlementMethod === 2) {
    return `运费 = ${left}min【装货${transportUnit}，卸货${transportUnit}】* ${transportPrice}${priceUnit}${
      data.lossRule
        ? ` - (装货${transportUnit} - 卸货${transportUnit} - ${
            data.allowLossNumberUnit === 1 ? `装货${transportUnit} *` : ``
          } ${new BigNumber(data.allowLossNumber)
            .dividedBy(conversion)
            .decimalPlaces(3, BigNumber.ROUND_DOWN)}${lossUnit}) * ${new BigNumber(data.lossPrice)
            .dividedBy(priceConversion)
            .decimalPlaces(2, BigNumber.ROUND_DOWN)}${priceUnit}`
        : ''
    }
        ${right}${otherFineText}${smallChangeRule}`;
  }
};
