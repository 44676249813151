<template>
  <div class="bg-white">
    <div class="relative font-500 title h-56 flex justify-center items-center">
      <span class="text-18 text-main">{{ title }}</span>
    </div>
    <div class="h-54vh">
      <slot />
    </div>
    <div class="px-24 py-24 van-hairline--top bg-white">
      <van-button type="primary" size="large" @click="handleConfirm">确认选择</van-button>
    </div>
  </div>
</template>

<script>
export default {
  // 1 车辆 2 收款账户
  props: ['type'],
  computed: {
    title() {
      return this.type === 1 ? '选择车辆' : '选择收款信息';
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('onConfirm');
    }
  }
};
</script>

<style scoped>
.h-54vh {
  height: 54vh;
}
</style>
